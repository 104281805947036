.screenResolation {
  max-width: 1280px;
}
.alignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerShadowSection {
  box-shadow: 0px 1px 2px 0px #1018280d;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
}
.headerSection {
  padding: 18px 0px;
}
.menuSection {
  display: flex;
  align-items: center;
  justify-content: center;
}
.menuSection > div {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  padding: 0px 12px;
  cursor: pointer;
}
.menuSection > div:hover {
  color: #0072bb;
}
.downloadApp {
  background-color: #0072bb;
  border: 1px solid #0072bb;
  box-shadow: 0px 6px 12px 0px #0000001f;
  color: #fff;
  border-radius: 8px;
  padding: 15px 30px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
}
.downloadApp:hover{
  background-color: #0072bb;
  border: 1px solid #0072bb;
  color: #fff;
}

.footerbackground {
  background-color: #ebf4fa;
}
.footerSection {
  padding: 48px 0px;
}
.socialIcon {
  background-color: #fff;
  padding: 12px;
  border-radius: 16px;
  margin-left: 24px;
}
.socialIconSection {
  display: flex;
  align-items: center;
  justify-content: end;
}
.InfoSection {
  text-align: end;
}
.centerfooterSection {
  padding: 32px 0px 50px;
}
.bottomSection {
  border-top: 1px solid #d1d1d1;
  padding: 32px 0px;
}
.policyLinkSection {
  display: flex;
  align-items: center;
  justify-content: end;
}
.policyLinkSection > a > div {
  padding-left: 16px;
}
.policyText {
  font-size: 14px;
  line-height: 18px;
  color: #656565;
}
.cursorPointer {
  cursor: pointer;
}
.textDecorationNone {
  text-decoration: none;
}
.policyText:hover {
  color: #0072bb;
}

.userDetailsLinkHeader {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: #7e7e7e;
  padding-bottom: 12px;
}
.userDetailsLink {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 8px;
  text-decoration: none;
}
.userDetailsLink > img {
  padding-right: 8px;
}
.userDetailsLink > p {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.leftAppLink {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.leftAppLink > p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  padding-bottom: 16px;
}
.leftAppLink > div > img {
  cursor: pointer;
}

.landingOuterSection {
  padding: 64px 0px 0px;
}
.welcomeBackground {
  background-image: url(../public/assets/herosectionbg.png);
  background-size: contain;
  background-repeat: no-repeat;
}

.landingPageInnerSection {
  padding: 64px;
}
.herotext {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  padding-bottom: 12px;
}
.herotextPera {
  font-size: 20px;
  line-height: 28px;
  color: #7f7f7f;
  padding-bottom: 64px;
}

/* Privacy Policy */

.head {
  padding: 64px 0px;
  text-align: center;
  width: 768px;
  max-width: 90%;
  margin: 0 auto;
}

.as-of {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.6px;
  letter-spacing: 0.03em;
  text-align: center;
  color: #004470;
  text-transform: uppercase;
}
.terms-title {
  font-family: Inter;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  letter-spacing: -0.02em;
  text-align: center;
}
.privacy-sub-title {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  margin-top: 20px;
  opacity: 0.6;
}
.privacy-content-area {
  width: 720px;
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 50px !important;
}
.privacy-content {
  margin-top: 20px;
  margin-bottom: 20px;
}
.privacy-content h2 {
  font-family: Inter;
  font-size: 30px;
  font-weight: 600;
  line-height: 38px;
}
.privacy-content p,
.privacy-content ul {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  opacity: 0.6;
}

.popularServicesBG {
  background-image: url(../public/assets/map.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.serviesHeader {
  text-align: center;
  padding-bottom: 32px;
  padding-top: 64px;
}
.serviesHeader > p {
  font-size: 14px;
  line-height: 20px;
  color: #004470;
  font-weight: 600;
}
.serviesHeader > h3 {
  font-size: 36px;
  line-height: 48px;
  font-weight: 600;
}

.popularServices {
  display: flex;
  align-items: center;
}
.innerPopularSection {
  max-width: 916px;
  box-shadow: 0px 10px 24px 0px #0000000f;
  border-radius: 32px;
  padding: 32px 32px 0px;
  margin-bottom: 32px;
}

.servicePopularTitle {
  font-size: 24px;
  line-height: 32px;
  color: #101828;
  padding-bottom: 24px;
  font-weight: 500;
}
.popularServicesAlign {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
}
.popularServicesAlign > img {
  padding-right: 8px;
}
.popularServicesBox {
  width: 50%;
}

.workjobSection {
  background-image: url(../public/assets/Content.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 32px;
  margin-bottom: 64px;
}
.servicesDetails {
  padding-bottom: 48px;
}
.servicesDetails > img {
  padding-bottom: 20px;
}
.servicesDetails > h6 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  padding-bottom: 8px;
}
.servicesDetails > p {
  font-size: 16px;
  line-height: 24px;
  color: #7f7f7f;
}

.jobTodaySection {
  background-image: url(../public/assets/Union.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.landingPageInnerSection.jobTodaySection {
  padding: 125px 0px;
}
.leftjobToday {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.faqSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 64px 0px;
}
.faqTitle {
  text-align: center;
  font-size: 36px;
  line-height: 48px;
  font-weight: 600;
  padding-bottom: 32px;
}
.service-toggle {
  position: relative;
  display: flex;
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 8px;
}
.service-toggleActive {
  background-color: #ebf4fa;
}
.toggle-icon {
  cursor: pointer;
}

.toggle-icon.hidden {
  display: none;
}

.toggle-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.toggle-content.open {
  max-height: 500px; /* Adjust this value based on your content */
}
.LeftmessageSectio > img {
  padding-right: 24px;
}
.rightmessageSectio {
  padding: 3px 0px;
}
.rightmessageSectio > h6 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  padding-bottom: 8px;
}

.rightmessageSectio > p {
  font-size: 16px;
  line-height: 22px;
  color: #7e8691;
}
.visibleMobile {
  display: none;
}

.leftAppLink img {
  margin-right: 10px;
}

.modalAlert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 40%;
}

.modalInnertext {
  text-align: center;
}
.modalInnertext > p {
  font-size: 30px;
  line-height: 45px;
  font-weight: 700;
}
.soonBtn {
  background-color: #0072bb;
  border: 1px solid #0072bb;
  box-shadow: 0px 6px 12px 0px #0000001f;
  color: #fff;
  border-radius: 8px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  padding:12px 25px;
}
/* .scroll-margin {
  margin-top: -100px;
  padding-top: -100px; 
} */

.form-control {
  border-radius: 6px !important;
  border: 1px solid #BDBDBD;
}

.form-control {
  font-size: 14px;
  line-height: 24px;
  color: #363636;
}

.acFormBackground{
  padding:20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.acFormBackground>div{
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  width: 100%;
}
.closeformTitle{
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;
  padding-bottom: 10px;
}

.acclountcloseForm{
  background-image: url(../public/assets/herosectionbg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.outerDeletePage{
  padding:64px 0px;
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-contentConfirm {
  width: 40%;
  background-color: white;
  padding: 30px 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}
.modal-contentConfirm div{
  display: flex;
  align-items: center;
  justify-content: end;
}

@media only screen and (max-width: 768px) {
  .menuSection {
    flex-direction: column;
  }
  .menuSection > div {
    padding: 10px;
  }
  .landingOuterSection {
    padding: 20px 0px 0px;
  }
  .landingPageInnerSection {
    padding: 20px;
  }
  .herotext {
    font-size: 26px;
    line-height: 32px;
  }
  .herotextPera {
    font-size: 18px;
    line-height: 26px;
    padding-bottom: 10px;
  }
  .serviesHeader > h3 {
    font-size: 26px;
    line-height: 32px;
  }
  .innerPopularSection {
    padding: 20px;
  }
  .leftAppLink > div > img {
    padding: 0px 10px;
  }
  .servicePopularTitle {
    padding: 15px 0px;
    font-size: 20px;
    line-height: 20px;
  }
  .serviesHeader {
    padding: 20px 0px;
  }
  .workjobSection {
    background-position: bottom;
    padding: 20px 20px 450px;
    margin-bottom: 0px;
  }
  .landingPageInnerSection.jobTodaySection {
    padding: 50px 20px 0px;
  }
  .leftjobToday {
    padding: 10px;
  }
  .faqTitle {
    font-size: 26px;
    line-height: 32px;
  }
  .footerSection {
    padding: 20px;
  }
  .leftAppLink {
    align-items: center;
  }
  .centerfooterSection {
    padding: 20px 0px;
  }
  .userDetailsLink {
    justify-content: center;
  }
  .userDetailsLinkHeader {
    text-align: center;
    padding-top: 20px;
  }
  .policyText {
    text-align: center;
    padding-bottom: 10px;
  }
  .policyLinkSection > a > div {
    padding: 5px;
  }
  .imgResponsive {
    text-align: center;
    padding: 20px 10px;
  }
  .imgResponsive > img {
    height: 300px;
  }
  .policyLinkSection {
    flex-direction: column;
  }
  .threeLine {
    position: absolute;
    left: 20px;
  }

  .menuSection,
  .downloadSection {
    opacity: 1;
    max-height: 500px; /* Adjust according to your content */
    transition: opacity 0.5s ease, max-height 0.5s ease;
  }

  /* Hidden state */
  .hideMenu {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease, max-height 0.5s ease;
  }

  .showMenu {
    opacity: 1;
    max-height: 500px; /* Adjust according to your content */
  }

  .threeLine {
    cursor: pointer;
  }
  .visibleMobile {
    display: block;
  }
  .modalAlert{
    width: 90%;
  }
  .modalInnertext > p{
    font-size: 24px;
    line-height: 32px;
  }
  .outerDeletePage{
    padding: 20px;
  }
  .closeformTitle{
    text-align: center;
  }
  .modal-contentConfirm{
    width: 90%;
  }
}
